var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white w-full inline-block border-1 md:border-l-1 lg:border-0 lg:border-l-1 border-grey-dark rounded-tl-1 rounded-bl-1 md:rounded-1 lg:rounded-none sg__date-wrap",class:{ 'relative bg-grey-light sg__error': _vm.showValidationErrors }},[(!_vm.showValidationErrors)?_c('label',{staticClass:"absolute pt-1 pl-2 text-xs",attrs:{"for":"carHirePickUpDate"}},[_vm._v("Pick up date")]):_vm._e(),(_vm.showValidationErrors)?_c('label',{staticClass:"absolute pt-1 pl-2 text-xs text-signal-error",attrs:{"for":"carHirePickUpDate"}},[_vm._v(_vm._s(_vm.validationErrors))]):_vm._e(),_c('div',{staticClass:"relative flex items-center"},[_c('flat-pickr',{key:_vm.datePickerKey,staticClass:"relative z-1 p-1 pt-4 pl-2 w-full bg-transparent text-lg font-bold cursor-pointer h-10",class:{ 'placeholder-black': !_vm.showValidationErrors, 'placeholder-signal-error': _vm.showValidationErrors },attrs:{"id":"carHirePickUpDate","data-cy":"carhire-pickup-date","name":"pickUpDate","type":"text","placeholder":"Start Date","readonly":"true","config":_vm.config},on:{"on-change":_vm.handlePickUpDateInput,"on-open":function($event){_vm.$generalClick({
          category: 'Search Gadget Interaction',
          label: 'Open Pickup Date',
        });
        _vm.$ga4GeneralClick({
          event: 'search_gadget',
          product: 'car hire',
          module: 'pick-up date',
          action: 'open'
        });}},model:{value:(_vm.pickUpDateSelected),callback:function ($$v) {_vm.pickUpDateSelected=$$v},expression:"pickUpDateSelected"}}),_c('dropdownArrowSvg',{attrs:{"validation-errors":_vm.showValidationErrors}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }