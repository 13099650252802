<template>
  <div
    class="bg-white w-full inline-block border-1 border-l-0 md:border-l-1 lg:border-0 lg:border-l-1 border-grey-dark rounded-tr-1 rounded-br-1 md:rounded-1 lg:rounded-none"
    v-bind:class="{ 'relative bg-grey-light sg__error': showValidationErrors }"
  >
    <label
      v-if="!showValidationErrors"
      for="carHireDropOffTime"
      class="absolute pt-1 pl-2 text-xs"
      >Drop off time</label
    >
    <label
      data-cy="carhire-dropoff-time-error"
      v-if="showValidationErrors"
      for="carHireDropOffTime"
      class="absolute pt-1 pl-2 text-xs text-signal-error"
      >{{ validationErrors }}</label
    >

    <div class="relative flex items-center">
      <select
        id="carHireDropOffTime"
        data-cy="carhire-dropoff-time"
        name="carHireDropOffTime"
        class="appearance-none w-full bg-transparent relative z-1 p-1 pt-4 pl-2 pr-6 text-lg font-bold h-10"
        v-bind:class="{ 'text-signal-error': showValidationErrors }"
        @focus="handleFocusDropOffTime"
        @change="handleSelectDropOffTime($event)"
        v-model="dropOffTimeSelected"
      >
        <option 
          v-for="time in times" 
          :key="time" 
          :value="time"
          :selected="time === '10:00'"
        >
          {{ time }}
        </option>
      </select>
      <dropdownArrowSvg
        :validation-errors="showValidationErrors"
      ></dropdownArrowSvg>
    </div>
  </div>
</template>

<script>
import Constants from "@/Constants";
import dropdownArrowSvg from "@/components/svg/gadgetDropDownArrow.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    validationErrors: String,
  },
  components: { dropdownArrowSvg },
  data() {
    return {
      dropOffTimeSelected: null,
    };
  },
  methods: {
    ...mapActions(["setDropOffTime"]),
    handleFocusDropOffTime() {
      this.$generalClick({
        category: "Search Gadget Interaction",
        label: "Open Drop Off Time",
      });
      this.$ga4GeneralClick({
        event: 'search_gadget',
        product: 'car hire',
        module: 'drop-off time',
        action: 'open'
      });
    },
    handleSelectDropOffTime(event) {
      const time = event.target.value;
      this.setDropOffTime(time ? time : null);
      this.$generalClick({
        category: "Search Gadget Interaction",
        label: `Drop Off Time_${time}`,
      });
      this.$ga4GeneralClick({
        event: 'search_gadget',
        product: 'car hire',
        module: 'drop-off time',
        action: 'select',
        content_display: time
      });
    },
  },
  beforeMount() {
    this.dropOffTimeSelected = this.dropOffTime;
  },
  computed: {
    ...mapGetters(["dropOffTime"]),
    times() {
      return Constants.TIMES;
    },
    showValidationErrors() {
      if (this.validationErrors) {
        return true;
      }
      return false;
    },
  },
};
</script>
