<template>
  <div
    class="row w-screen h-full m-0 fixed top-0 left-0 z-1000 flex items-center justify-center bg-black-alpha-2"
  >
    <div
      class="w-full md:w-11/12 lg:w-8/12 bg-white p-8 relative text-center h-full lg:h-auto lg:min-h-1/4 lg:max-h-3/4 scrolling-touch rounded"
    >
      <button
        ref="close"
        class="bg-white p-0 top-0 right-0 mt-3 mr-3 absolute text-primary"
        @click="closeModal();"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="27"
          width="27"
          viewBox="0 0 12 12"
          class="fill-current transform rotate-45"
        >
          <path d="M12 5.1V7H6.9v5H5.1V6.9H0V5.1h5.1V0H7v5.1h5z" />
        </svg>
      </button>
      <div class="h-full overflow-y-auto">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    idx: Number
  },
  beforeMount() {
    document.body.classList.add("overflow-hidden");
    if(document.getElementsByTagName("header")[0]) {
      document.getElementsByTagName("header")[0].classList.add("hidden", "lg:block");
    }
  },
  mounted() {
    this.$refs.close.focus()
  },
  methods: {
    ...mapActions(["clearModal"]),
    closeModal() {
      this.clearModal();
      document.body.classList.remove("overflow-hidden");
      if(document.getElementsByTagName("header")[0]) {
        document.getElementsByTagName("header")[0].classList.remove("hidden", "lg:block");
      }
    },
  },
  computed: {
    ...mapGetters(["modalName"])
  }
};
</script>