<template>
  <div
    class="bg-white w-full inline-block border-1 md:border-l-1 lg:border-0 lg:border-l-1 border-grey-dark rounded-tl-1 rounded-bl-1 md:rounded-1 lg:rounded-none sg__date-wrap"
    v-bind:class="{ 'relative bg-grey-light sg__error': showValidationErrors }"
  >
    <label
      v-if="!showValidationErrors"
      for="carHirePickUpDate"
      class="absolute pt-1 pl-2 text-xs"
      >Pick up date</label
    >
    <label
      v-if="showValidationErrors"
      for="carHirePickUpDate"
      class="absolute pt-1 pl-2 text-xs text-signal-error"
      >{{ validationErrors }}</label
    >
    <div class="relative flex items-center">
      <flat-pickr
        id="carHirePickUpDate"
        :key="datePickerKey"
        data-cy="carhire-pickup-date"
        name="pickUpDate"
        type="text"
        class="relative z-1 p-1 pt-4 pl-2 w-full bg-transparent text-lg font-bold cursor-pointer h-10"
        :class="{ 'placeholder-black': !showValidationErrors, 'placeholder-signal-error': showValidationErrors }"
        placeholder="Start Date"
        readonly="true"
        v-model="pickUpDateSelected"
        :config="config"
        @on-change="handlePickUpDateInput"
        @on-open="
          $generalClick({
            category: 'Search Gadget Interaction',
            label: 'Open Pickup Date',
          });
          $ga4GeneralClick({
            event: 'search_gadget',
            product: 'car hire',
            module: 'pick-up date',
            action: 'open'
          });
        "
      ></flat-pickr>
      <dropdownArrowSvg
        :validation-errors="showValidationErrors"
      ></dropdownArrowSvg>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import HelperFunctions from "@/HelperFunctions";
import dropdownArrowSvg from "@/components/svg/gadgetDropDownArrow.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  props: { daysAhead: Number, validationErrors: String },
  components: { dropdownArrowSvg },
  data() {
    return {
      datePickerKey: "1",
      datePickerCookieName: "dateConfigCarhire",
      datePickerConfig: null,
      pickUpDateSelected: null,
      config: {
        disableMobile: true,
        disable: [],
        locale: {
            firstDayOfWeek: 1,
        },
        minDate: HelperFunctions.dateIncrement(1),
        maxDate: HelperFunctions.dateIncrement(529)
      }
    };
  },
  beforeMount() {
    this.setInitialDate();
    Vue.set(this.config, "dateFormat", "j M y");
  },
  computed: {
    ...mapGetters(["pickUpDate"]),
    showValidationErrors() {
      if (!this.pickUpDate && this.validationErrors) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.$root.$on("pickUpLocationDateChange", (val) => {
      if (val && typeof val === 'string') {
        const tmpDateArr = val.split('-')
        const earliestDateForLocation = new Date(Date.UTC(tmpDateArr[2], tmpDateArr[1]-1, tmpDateArr[0], 0, 0, 0, 0))

        if(earliestDateForLocation.getTime() >= HelperFunctions.dateIncrement(1).getTime()) {
          if(this.pickUpDateSelected){
            const pickUpDateSelectedEpoch = new Date(HelperFunctions.parseToISODate(this.pickUpDateSelected)).getTime()
            if(earliestDateForLocation.getTime() > pickUpDateSelectedEpoch) {
              this.setPickUpDate('')
              this.pickUpDateSelected = ''
            }
          }
          Vue.set(this.config, "minDate", earliestDateForLocation);
          this.$root.$emit("pickUpDateChange", HelperFunctions.dateParseToReadable(`${tmpDateArr[2]}-${tmpDateArr[1]}-${tmpDateArr[0]}`));
        }
        else {
          Vue.set(this.config, "minDate", HelperFunctions.dateIncrement(1));
        }
      } else {
        Vue.set(this.config, "minDate", HelperFunctions.dateIncrement(1));
      }
      //This generates new key every time, forcing datepicker reactivity
      this.datePickerKey = Math.random()
          .toString(36)
          .replace(/[^a-z]+/g, "")
          .substr(0, 5);
    });
  },  
  methods: {
    ...mapActions(["setPickUpDate"]),
    setInitialDate() {
      if (this.daysAhead) {
        const date = HelperFunctions.dateParseNowToUrlFriendly(
          HelperFunctions.dateIncrement(this.daysAhead)
        );
        this.setPickUpDate(date);
        this.pickUpDateSelected = HelperFunctions.dateParseToReadable(date);
      }
    },
    handlePickUpDateInput(selectedDates, dateString) {
      this.setPickUpDate(HelperFunctions.parseToISODate(dateString));
      this.$root.$emit("pickUpDateChange", HelperFunctions.parseToISODate(dateString));
      this.$generalClick({
        category: "Search Gadget Interaction",
        label: `Pickup Date_${HelperFunctions.parseToISODate(dateString)}`,
      });
      let ga4Date = new Date(selectedDates)
      this.$ga4GeneralClick({
        event: 'search_gadget',
        product: 'car hire',
        module: 'pick-up date',
        action: 'select',
        content_display: new Intl.DateTimeFormat('en-GB').format(ga4Date)
      });
    },
  },
};
</script>
