import { render, staticRenderFns } from "./PickUpDate.vue?vue&type=template&id=4865010d&"
import script from "./PickUpDate.vue?vue&type=script&lang=js&"
export * from "./PickUpDate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  "74dd68e9"
  
)

export default component.exports