<template>
  <div class="flex mb-2 lg:mb-0 md:pr-2 lg:pr-0">
    <div v-if="!showDriverAgeSelect" class="lg:pl-2">
      <span class="block mb-1 text-secondary text-xs">Driver's age</span>
      <input
        type="checkbox"
        data-cy="carhire-driver-age-checkbox"
        id="driver-age-check"
        checked
        class="absolute -left-9999 checkbox-label-focus--dark"
        @change="uncheckDriverAge()"
      />
      <label
        class="flex w-full relative items-center mr-2 text-secondary text-sm"
        for="driver-age-check"
      >
        <span
          class="bg-white border-1 border-grey-dark flex items-center justify-center mr-2 h-5 min-w-5 w-5"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="text-primary fill-current h-3 w-3"
            height="11"
            width="14"
            viewBox="0 0 15 12"
            data-cy="carhire-return-tick-icon"
          >
            <path
              d="M15 1.6L4.6 12 0 7.3l1.5-1.6 3.1 3.1L13.4 0 15 1.6z M15 1.6L4.6 12 0 7.3l1.5-1.6 3.1 3.1L13.4 0 15 1.6z"
            />
          </svg>
        </span> Age 25 to 65</label>
    </div>

    <div
      v-if="showDriverAgeSelect"
      class="bg-white w-1/2 lg:w-full inline-block border-1 md:border-l-1 lg:border-0 lg:border-l-1 border-grey-dark rounded"
    >
      <label for="driverAge" class="absolute pt-1 pl-2 text-xs"
        >Driver's age</label
      >
      <div class="relative flex items-center">
        <select
          v-model="driverAgeSelected"
          name="driverAge"
          data-cy="carhire-driver-age-dropdown"
          id="driverAge"
          class="appearance-none w-full bg-transparent relative z-1 p-1 pt-4 pl-2 pr-6 text-lg font-bold h-10"
          @focus="handleFocusDriverAge"
          @change="handleSelectDriverAge"
        >
          <option v-for="n in ages" :key="n" :value="n">{{ n }}</option>
        </select>
        <dropdownArrowSvg></dropdownArrowSvg>
      </div>
    </div>

    <button type="button" class="flex items-center justify-center min-w-6 w-6" :class="{'pt-4': !showDriverAgeSelect}" @click="openAgeInfoModal()">
      <svg xmlns="http://www.w3.org/2000/svg" class="text-secondary fill-current" height="12" width="7" viewBox="0 0 6.8 17.7" aria-labelledby="driver-age-tooltip-icon"><title id="driver-age-tooltip-icon">Open driver age information</title><path d="M.3 14.7c0-.2.1-.4.1-.6L2 8.3l.1-.4c0-.2 0-.3-.1-.4 0-.2-.1-.2-.2-.3-.1 0-.3-.1-.5-.1S.9 7 .6 7v-.5c.3 0 .6 0 .9-.1s.7-.1 1.2-.1c.5-.1.9-.1 1.2-.2.3-.1.6-.1.8-.2l1.1-.2L3.7 13c-.1.3-.1.6-.2.8s-.1.4-.1.5c-.1.3-.2.5-.2.8s-.1.3-.1.4v.2s0 .1.1.1l.1.1h.1c.1 0 .3-.1.4-.2.2-.1.4-.3.6-.5.2-.2.4-.4.6-.7s.3-.5.4-.7l.6.3c-.4.6-.8 1.1-1.1 1.5-.3.4-.6.7-.8.9-.3.5-.7.7-1.1.9-.4.2-.8.3-1.2.3-.3 0-.5 0-.7-.1l-.6-.3c-.2 0-.3-.2-.4-.4-.1-.3-.1-.6-.1-.9 0-.1 0-.2.1-.4 0-.2.1-.4.1-.6 0 0 .1-.2.1-.3zM6.7 1.1c.1.2.1.4.1.6 0 .3 0 .5-.1.7-.1.1-.2.3-.4.5s-.4.3-.6.4c-.2.1-.4.1-.7.1-.3 0-.5 0-.7-.1-.2-.1-.4-.2-.6-.4-.1-.2-.3-.4-.3-.6s-.2-.4-.2-.6c0-.3 0-.5.1-.7s.2-.4.4-.5c.3-.2.5-.3.7-.4.2-.1.4-.1.6-.1.2 0 .4 0 .7.1.2.1.4.2.6.4.2.2.3.4.4.6z"></path></svg>
    </button>
  </div>
</template>

<script>
import dropdownArrowSvg from "@/components/svg/gadgetDropDownArrow.vue";
import Constants from "@/Constants";
import { mapGetters, mapActions } from "vuex";

export default {
  components: { dropdownArrowSvg },
  props: {
    driverAgePreset: { type: Number, default: 33 },
    isDriverAged: { type: Boolean, default: true },
  },
  data() {
    return {
      showDriverAgeSelect: false,
      driverAgeSelected: 33,
    };
  },
  beforeMount() {
    this.setInitialAge();
  },
  methods: {
    ...mapActions(["setDriverAge", "openModal"]),
    setInitialAge() {
      if (this.driverAgePreset && !this.isDriverAged) {
        this.driverAgeSelected = this.driverAgePreset;
        this.setDriverAge(this.driverAgePreset);
        this.showDriverAgeSelect = true;
      }
    },
    uncheckDriverAge() {
      this.showDriverAgeSelect = true;
      this.$generalClick({
        category: "Search Gadget Interaction",
        label: `Uncheck Driver Age`,
      });
      this.$ga4GeneralClick({
        event: 'search_gadget',
        product: 'car hire',
        module: "driver's age",
        action: 'untick'
      })
    },
    handleFocusDriverAge() {
      this.$generalClick({
        category: "Search Gadget Interaction",
        label: "Open Driver Age",
      });
    },
    handleSelectDriverAge(event) {
      const age = parseInt(event.target.value);
      this.setDriverAge(age);
      this.$generalClick({
        category: "Search Gadget Interaction",
        label: `Driver Age_${age}`,
      });
      this.$ga4GeneralClick({
        event: 'search_gadget',
        product: 'car hire',
        module: "driver's age",
        action: 'select',
        content_display: age
      })
    },
    openAgeInfoModal() {
      this.openModal({ name: "DriverAgeInfo" });
      this.$generalClick({
        category: "Search Gadget Interaction",
        label: `Driver age tooltip`,
      });
      this.$ga4GeneralClick({
         event: 'search_gadget',
         product: 'car hire',
         module: "tooltip information",
         action: 'open'
      })
      
    },
  },
  computed: {
    ...mapGetters(["driverAge"]),
    ages() {
      return Constants.DRIVER_AGES;
    },
  },
};
</script>
