<template>
  <div
    class="bg-white rounded-1 lg:rounded-none border-1 lg:border-0 border-grey-dark"
    :class="{'bg-grey-light sg__error' : showValidationErrors}"
  >
    <auto
      @input="handleLocationInput"
      channel="Car Hire"
      ga-label="Pickup From"
      ga4-label="pick-up from"
      :endpoint="endpoint"
      endpointLocations="/global/reference-data/v1/locations/carhire/nearest/?"
      display-value="name"
      id="carhire-pickup"
      placeholder="Enter Location"
      label="Pick up from"
      :validation-errors="validationErrors"
      :show-validation-errors="showValidationErrors"
      :value="pickUpLocationSelected"
      ref="carhirePickUp"
    ></auto>
  </div>
</template>

<script>
import Auto from "@/components/holidaysGadget/AutoComplete.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: { Auto },
  props: { location: Object, validationErrors: String},
  data() {
    return {
      pickUpLocationSelected: this.location || {},
      endpoint: '/global/reference-data/v1/locations/carhire/search/'
    };
  },
  created() {
    if (this.location) {
      this.setLocation(this.location)
    }
  },
  beforeDestroy() {
    //Ensures emit does not fire twice
    this.$root.$off('pickUpLocationDateChange', this.handleLocationInput)
  },
  methods: {
    ...mapActions(["setPickUpLocation"]),
    handleLocationInput(selectedItem) {
      this.setLocation(selectedItem)  
    },
    setLocation(destination){
      this.setPickUpLocation(destination ? destination : null)
      if(destination && destination.searchableFrom){
        this.$root.$emit("pickUpLocationDateChange", destination.searchableFrom);
      }
    },
    prePopulateDestination(searchTerm) {
      if (!this.location) {
        import(/* webpackChunkName: "tsm-axios" */ "axios").then((Axios) => {
            Axios.get(`${this.apiDomain}${this.endpoint}${searchTerm}`)
              .then((response) => {
                const location = response.data.locations[0];
                if(location){
                  this.setLocation(location)
                  this.$refs.carhirePickUp.updateVisibleText(
                    location.name
                  );
                }
              })
              .catch(() => {
                //Do nothing, we just need to handle the error and continue.
              })
          });
      }
    },
  },
  computed: {
    ...mapGetters(["pickUpLocation", "returnToSameLocation"]),
    showValidationErrors(){
      if (!this.pickUpLocation && this.validationErrors) {
        return true;
      }
      return false;
    }
  },
  mounted() {
    this.apiDomain = (window.location.hostname === 'www.travelsupermarket.com') ? this.$store.getters.apiUrlProd : this.$store.getters.apiUrlTest;

    const urlSearchParams = new URLSearchParams(window.location.search);
    const destinationName = urlSearchParams.get('destinationName')
    if (destinationName && !this.location) {
      this.prePopulateDestination(destinationName)
    }
    if (this.location) {
      // TODO: due to the v-modal bug, see updateVisibleText function in AutoComplete.vue
      // this will mean the input does not have value from SSR, and only on Vue mount
      this.$refs.carhirePickUp.updateVisibleText(
        this.location.name
      );
    }
  },
};
</script>
