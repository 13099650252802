<template>
  <div
    class="bg-white w-full inline-block border-1 border-l-0 md:border-l-1 lg:border-0 lg:border-l-1 border-grey-dark rounded-tr-1 rounded-br-1 md:rounded-1 lg:rounded-none"
  >
    <label for="carHirePickUpTime" class="absolute pt-1 pl-2 text-xs"
      >Pick up time</label
    >

    <div class="relative flex items-center">
      <select
        id="carHirePickUpTime"
        data-cy="carhire-pickup-time"
        name="carHirePickUpTime"
        class="appearance-none w-full bg-transparent relative z-1 p-1 pt-4 pl-2 pr-6 text-lg font-bold h-10"
        v-model="pickUpTimeSelected"
        @focus="handleFocusPickUpTime"
        @change="handleSelectPickUpTime($event)"
      >
        <option 
          v-for="time in times" 
          :key="time" 
          :value="time"
          :selected="time === '10:00'"
        >
          {{ time }}
        </option>
      </select>
      <dropdownArrowSvg></dropdownArrowSvg>
    </div>
  </div>
</template>

<script>
import Constants from "@/Constants";
import dropdownArrowSvg from "@/components/svg/gadgetDropDownArrow.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: { dropdownArrowSvg },
  data() {
    return {
      pickUpTimeSelected: null,
    };
  },
  methods: {
    ...mapActions(["setPickUpTime"]),
    handleFocusPickUpTime() {
      this.$generalClick({
        category: "Search Gadget Interaction",
        label: "Open Pickup Time",
      });
      this.$ga4GeneralClick({
        event: 'search_gadget',
        product: 'car hire',
        module: 'pick-up time',
        action: 'open'
      });
    },
    handleSelectPickUpTime(event) {
      const time = event.target.value;
      this.setPickUpTime(time ? time : null);
      this.$generalClick({
        category: "Search Gadget Interaction",
        label: `Pickup Time_${time}`,
      });
      this.$ga4GeneralClick({
        event: 'search_gadget',
        product: 'car hire',
        module: 'pick-up time',
        action: 'select',
        content_display: time
      });
    },
  },
  beforeMount() {
    this.pickUpTimeSelected = this.pickUpTime;
  },
  computed: {
    ...mapGetters(["pickUpTime"]),
    times() {
      return Constants.TIMES;
    },
  },
};
</script>
