<template>
  <div class="w-full relative p-2 bg-white text-secondary block rounded gadget">
    <div class="gadget-divide"></div>
    <form
      id="carHireGadget"
      data-cy="car-hire-gadget"
      autocomplete="off"
      @submit.prevent="handleSubmit"
      class="flex flex-wrap m-0 w-full"
    >
      <div class="w-full" v-if="carHireValidationErrors.gadget">
        <span
          class="block text-grey-lighter bg-signal-error text-sm p-1 pl-2 w-full z-1"
          >{{ carHireValidationErrors.gadget }}</span
        >
      </div>

      <div class="flex flex-wrap flex-auto w-full lg:w-9/12">
        <div
          class="w-full relative mb-2"
          :class="{
            'lg:w-4/12': returnToSameLocation,
            'lg:w-8/12 order-first': !returnToSameLocation,
          }"
        >
          <PickUpDestination
            :location="data.pickUpLocation"
            :validationErrors="carHireValidationErrors.pickUpLocation"
          ></PickUpDestination>
        </div>

        <div
          class="w-full lg:w-8/12 mb-2 relative order-1 lg:order-4 lg:mt-2"
          v-show="!returnToSameLocation"
        >
          <DropOffDestination
            :location="data.dropOffLocation"
            :validationErrors="carHireValidationErrors.dropOffLocation"
          ></DropOffDestination>
        </div>

        <div
          class="mb-2 lg:mb-0 w-full lg:w-auto lg:order-last"
          :class="{ 'order-2': !returnToSameLocation }"
        >
          <input
            type="checkbox"
            id="carHire-return"
            data-cy="carhire-return"
            checked
            class="absolute -left-9999 checkbox-label-focus--dark"
            @change="returnToSameLocationToggle($event)"
          />
          <label
            class="flex w-full relative items-center text-secondary text-sm mt-2"
            for="carHire-return"
          >
            <span
              class="bg-white border-1 border-grey-dark flex items-center justify-center mr-2 h-5 min-w-5 w-5"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="text-primary fill-current h-3 w-3"
                height="11"
                width="14"
                viewBox="0 0 15 12"
                v-if="returnToSameLocation"
                data-cy="carhire-return-tick-icon"
              >
                <path
                  d="M15 1.6L4.6 12 0 7.3l1.5-1.6 3.1 3.1L13.4 0 15 1.6z
                M15 1.6L4.6 12 0 7.3l1.5-1.6 3.1 3.1L13.4 0 15 1.6z"
                />
              </svg>
            </span>
            Return to same location</label
          >
        </div>

        <div
          class="w-1/2 lg:w-2/12 mb-2 md:pr-1 lg:pr-0"
          :class="{ 'order-2 lg:order-2': !returnToSameLocation }"
        >
          <PickUpDate
            :daysAhead="data.pickUpDate"
            :validationErrors="carHireValidationErrors.pickUpDate"
          ></PickUpDate>
        </div>

        <div
          class="w-1/2 lg:w-2/12 mb-2 md:pl-1 lg:pl-0"
          :class="{ 'order-2 lg:order-3': !returnToSameLocation }"
        >
          <PickUpTime></PickUpTime>
        </div>

        <div
          class="w-1/2 lg:w-2/12 mb-2 md:pr-1 lg:pr-0"
          :class="{ 'order-2 lg:order-5 lg:mt-2': !returnToSameLocation }"
        >
          <DropOffDate
            :daysAhead="data.dropOffDate"
            :pickUpDaysAhead="data.pickUpDate"
            :validationErrors="carHireValidationErrors.dropOffDate"
          ></DropOffDate>
        </div>

        <div
          class="w-1/2 lg:w-2/12 mb-2 md:pl-1 lg:pl-0"
          :class="{ 'order-2 lg:order-6 lg:mt-2': !returnToSameLocation }"
        >
          <DropOffTime
            :validationErrors="carHireValidationErrors.dropOffTime"
          ></DropOffTime>
        </div>
      </div>

      <div class="w-full lg:w-2/12">
        <DriverAge
          :driverAgePreset="data.driverAge"
          :isDriverAged="data.isDriverAged"
        ></DriverAge>
      </div>

      <div class="w-full lg:w-1/12">
        <button
          data-cy="car-hire-gadget-submit"
          type="submit"
          value="Search"
          class="w-full p-0 bg-text-link hover:bg-text-link-dark text-white rounded flex items-center justify-center text-xl md:text-base h-10"
        >
          <SearchIcon></SearchIcon>
          Search
        </button>
      </div>
    </form>

    <Modal v-if="isModalOpen">
      <div v-if="modalName === 'DriverAgeInfo'">
        <h2 class="mt-2 text-xl font-bold text-left">Driver Age Information</h2>

        <p class="mt-3 mb-5 text-left">
          Due to insurance regulations, TravelSupermarket is unable to offer car
          rental products to drivers aged 17-20 or over 75.
        </p>

        <p class="mt-3 mb-5 text-left">
          Drivers aged between 21 and 25 or over 65 years old at the time of rental may be
          subject to a surcharge. This fee is included in your quote, 
          but is generally payable on pick up of your vehicle.          
        </p>
      </div>
    </Modal>
  </div>
</template>

<script>
import PickUpDestination from "@/components/carHireGadget/PickUpDestination.vue";
import PickUpDate from "@/components/carHireGadget/PickUpDate.vue";
import PickUpTime from "@/components/carHireGadget/PickUpTime.vue";
import DropOffDestination from "@/components/carHireGadget/DropOffDestination.vue";
import DropOffDate from "@/components/carHireGadget/DropOffDate.vue";
import DropOffTime from "@/components/carHireGadget/DropOffTime.vue";
import SearchIcon from "@/components/svg/gadgetSearchIcon.vue";
import DriverAge from "@/components/carHireGadget/DriverAge.vue";
import Modal from "@/components/Modal.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  props: { data: Object },
  components: {
    PickUpDestination,
    PickUpDate,
    PickUpTime,
    DropOffDestination,
    DropOffDate,
    DropOffTime,
    DriverAge,
    Modal,
    SearchIcon,
  },
  methods: {
    ...mapActions([
      "setReturnToSameLocation",
      "validateCarHireGadget",
      "submitCarHireGadget",
    ]),
    sendToGa(label, action, dimensions) {
      if (this.$generalClick !== undefined) {
        this.$generalClick({
          category: "Search Gadget Interaction",
          action: action ? action : "Error",
          label,
          dimensions,
        });
      }
    },
    returnToSameLocationToggle(evt) {
      this.setReturnToSameLocation(evt.target.checked);
      let label = evt.target.checked
        ? "Return to Same Location"
        : "Return to Different Location";
      this.sendToGa(label, "Click");

      this.$ga4GeneralClick({
        event: 'search_gadget',
        product: 'car hire',
        module: 'return same location',
        action: evt.target.checked ? 'tick' : 'untick'
      })
    },
    handleSubmit() {
      this.validateCarHireGadget();
      this.validationErrors = this.carHireValidationErrors;

      if (Object.entries(this.validationErrors).length === 0) {
        this.sendToGa("Car Hire Search Button", "Click", {
          dimension28: this.pickUpLocation.name,
          dimension96: this.returnToSameLocation
            ? this.pickUpLocation.name
            : this.dropOffLocation.name,
          dimension29: this.pickUpDate,
          dimension31: this.pickUpTime,
          dimension30: this.dropOffDate,
          dimension32: this.dropOffTime,
          dimension33: this.driverAge > 25 ? 1 : 0,
        });
        
        const ga4PickUpDate = new Date(this.pickUpDate)
        const ga4DropOffDate = new Date(this.dropOffDate)

        let ga4Obj = {
          event: 'search_gadget',
          product: 'car hire',
          module: 'buttonsearch',
          action: 'search deal',
          pickup_location_type: this.pickUpLocation.type ? this.pickUpLocation.type : null,
          pickup_location: this.pickUpLocation.name,
          pickup_date: new Intl.DateTimeFormat('en-GB').format(ga4PickUpDate),
          pickup_time: this.pickUpTime,
          dropoff_location: this.returnToSameLocation ? null : this.dropOffLocation.name,
          dropoff_location_type: !this.returnToSameLocation && this.dropOffLocation.type ? this.dropOffLocation.type : null,
          dropoff_date: new Intl.DateTimeFormat('en-GB').format(ga4DropOffDate),
          dropoff_time: this.dropOffTime,
          one_way_rental: !this.returnToSameLocation && this.dropOffLocation.id !== this.pickUpLocation.id,
          drivers_age: this.driverAge
        }

        this.$ga4GeneralClick(ga4Obj)

        this.submitCarHireGadget();

        this.validationErrors = this.carHireValidationErrors;
      } else {
        if (this.carHireValidationErrors.pickUpLocation) {
          this.sendToGa("Car Hire Pick Up Validation");
        }
        if (this.carHireValidationErrors.dropOffLocation) {
          this.sendToGa("Car Hire Drop Off Validation");
        }
        if (this.carHireValidationErrors.pickUpDate) {
          this.sendToGa("Car Hire Pick Up Date Validation");
        }
        if (this.carHireValidationErrors.dropOffDate) {
          this.sendToGa("Car Hire Drop Off Date Validation");
        }
        if (this.carHireValidationErrors.dropOffTime) {
          this.sendToGa("Car Hire Drop Off Time Validation");
        }
        window.scrollTo(0, 0);
      }
    },
  },
  computed: {
    ...mapGetters([
      "carHireValidationErrors",
      "returnToSameLocation",
      "pickUpLocation",
      "dropOffLocation",
      "pickUpDate",
      "pickUpTime",
      "dropOffLocation",
      "dropOffDate",
      "dropOffTime",
      "driverAge",
      "isModalOpen",
      "modalName",
    ]),
  },
};
</script>

<style lang="scss">
.gadget {
  box-shadow: 0 2px 16px 0 rgba(33, 43, 54, 0.08),
    0 0 0 1px rgba(6, 44, 82, 0.08);
}
.gadget-divide {
  height: 1px;
  @apply bg-grey-dark absolute hidden;
  // top: calc(50% + 10px);
  top: 70px;
  width: calc(100% - 20px);
  @screen lg {
    @apply block;
  }
}
</style>