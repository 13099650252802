<template>
  <div
    class="bg-white w-full inline-block border-1 md:border-l-1 lg:border-0 lg:border-l-1 border-grey-dark rounded-tl-1 rounded-bl-1 md:rounded-1 lg:rounded-none sg__date-wrap"
    v-bind:class="{ 'relative bg-grey-light sg__error': showValidationErrors }"
  >
    <label
      v-if="!showValidationErrors"
      for="carHireDropOffDate"
      class="absolute pt-1 pl-2 text-xs"
      >Drop off date</label
    >
    <label
      v-if="showValidationErrors"
      for="carHireDropOffDate"
      class="absolute pt-1 pl-2 text-xs text-signal-error"
      >{{ validationErrors }}</label
    >
    <div class="relative flex items-center">
      <flat-pickr
        :key="datePickerKey"
        id="carHireDropOffDate"
        data-cy="carhire-dropoff-date"
        name="dropoffDate"
        type="text"
        class="relative z-1 p-1 pt-4 pl-2 w-full bg-transparent text-lg font-bold cursor-pointer h-10"
        :class="{ 'placeholder-black': !showValidationErrors, 'placeholder-signal-error': showValidationErrors }"
        placeholder="End Date"
        readonly="true"
        v-model="dropOffDateSelected"
        :config="config"
        @on-change="handleDropOffDateInput"
        @on-open="
          $generalClick({
            category: 'Search Gadget Interaction',
            label: 'Open Drop Off Date',
          });
          $ga4GeneralClick({
            event: 'search_gadget',
            product: 'car hire',
            module: 'drop-off date',
            action: 'open'
          });
        "
      ></flat-pickr>
      <dropdownArrowSvg
        :validation-errors="showValidationErrors"
      ></dropdownArrowSvg>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import HelperFunctions from "@/HelperFunctions";
import dropdownArrowSvg from "@/components/svg/gadgetDropDownArrow.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    daysAhead: Number,
    pickUpDaysAhead: Number,
    validationErrors: String,
  },
  components: { dropdownArrowSvg },
  data() {
    return {
      datePickerKey: "1",
      datePickerCookieName: "dateConfigCarhire",
      datePickerConfig: null,
      dropOffDateSelected: null,
      config: {
        disableMobile: true,
        locale: {
            firstDayOfWeek: 1,
        },
        minDate: HelperFunctions.dateIncrement(1),
        maxDate: HelperFunctions.dateIncrement(529),
      }
    };
  },
  beforeMount() {
    this.setInitialDate();
    Vue.set(this.config, "dateFormat", "j M y");
  },
  mounted() {
    this.$root.$on("pickUpDateChange", (val) => {
      Vue.set(this.config, "minDate", new Date(val));
      if (
        this.dropOffDateSelected &&
        new Date(val).getTime() >=
          new Date(
            HelperFunctions.parseToISODate(this.dropOffDateSelected)
          ).getTime()
      ) {
        this.setDropOffDate(null);
        this.dropOffDateSelected = null;
      }

      //This generates new key every time, forcing datepicker reactivity
      this.datePickerKey = Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, "")
        .substr(0, 5);
    });
  },
  computed: {
    ...mapGetters(["dropOffDate", "pickUpDate"]),
    showValidationErrors() {
      if (!this.dropOffDate && this.validationErrors) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions(["setDropOffDate"]),
    setInitialDate() {
      if (this.daysAhead) {
        let date;

        if (this.pickUpDaysAhead) {
          date = HelperFunctions.dateParseNowToUrlFriendly(
            HelperFunctions.dateIncrement(this.daysAhead + this.pickUpDaysAhead)
          );
          Vue.set(
            this.config,
            "minDate",
            new Date(HelperFunctions.dateIncrement(this.pickUpDaysAhead))
          );
        } else {
          date = HelperFunctions.dateParseNowToUrlFriendly(
            HelperFunctions.dateIncrement(this.daysAhead)
          );
        }
        this.setDropOffDate(date);
        this.dropOffDateSelected = HelperFunctions.dateParseToReadable(date);
      }
    },
    handleDropOffDateInput(selectedDates, dateString) {
      if (dateString) {
        this.setDropOffDate(HelperFunctions.parseToISODate(dateString));
        this.$generalClick({
          category: "Search Gadget Interaction",
          label: `Drop Off Date_${HelperFunctions.parseToISODate(dateString)}`,
        });
        let ga4Date = new Date(selectedDates)
        this.$ga4GeneralClick({
          event: 'search_gadget',
          product: 'car hire',
          module: 'drop-off date',
          action: 'select',
          content_display: new Intl.DateTimeFormat('en-GB').format(ga4Date)
        });
      } else {
        return false;
      }
    },
  },
};
</script>
