<template>
  <div
    class="bg-white rounded-1 lg:rounded-none border-1 lg:border-0 border-grey-dark"
    :class="{'bg-grey-light sg__error' : showValidationErrors}"
  >
    <auto
      @input="handleLocationInput"
      channel="Car Hire"
      ga-label="Return From"
      ga4-label="drop-off location"
      endpoint="/global/reference-data/v1/locations/carhire/search/"
      display-value="name"
      id="carhire-dropoff"
      placeholder="Enter Location"
      label="Drop off at"
      :validation-errors="validationErrors"
      :show-validation-errors="showValidationErrors"
      :value="dropOffLocationSelected"
    ></auto>
  </div>
</template>

<script>
import Auto from "@/components/holidaysGadget/AutoComplete.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  components: { Auto },
  props: { location: Object, validationErrors: String },
  data() {
    return {
      dropOffLocationSelected: this.location || {}
    };
  },
  created() {
    if (this.location) {
      this.setDropOffLocation({
        name: this.location.name,
        id: this.location.id
      });
    }
  },
  methods: {
    ...mapActions(["setDropOffLocation"]),
    handleLocationInput(selectedItem) {
      this.setDropOffLocation(selectedItem ? selectedItem : null)
    }
  },
  computed: {
    ...mapGetters(["dropOffLocation"]),
    showValidationErrors() {
      if (!this.dropOffLocation && this.validationErrors) {
        return true;
      }
      return false;
    }
  },
};
</script>
